@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.animate-fade-in {
  animation: fadeIn 1s;
}

.animate-slide-in {
  animation: slideIn 1s ease-out forwards;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  border: none;
  outline: none;
}

*:active {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -ms-user-select: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: poppins, sans-serif;
  user-select: none;
  background: white;
}

.App {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center {
  max-width: 950px;
  width: 100vw;
  font-weight: 400;
  color: hsl(0, 0%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 300px) {
  .center {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 301px) and (max-width: 499px) {
  .center {
    font-size: 0.8em;
  }
}
@media screen and (min-width: 500px) {
  .center {
    font-size: 0.9em;
  }
}

.left,
.right {
  height: 100vh;
  width: calc((100vw - 950px) / 2);
  grid-row: 1/3; /* Spans from the first to the second row */
  z-index: 12;
  z-index: 12;
}

main {
  flex-grow: 1;
  padding: 2em;
  z-index: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
main .combinedTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.2em;
}
main h4 {
  font-size: 0.7em;
  padding-bottom: 0.2em;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
p,
table {
  margin: 0;
  padding: 0;
}

p {
  margin: 1em 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table,
th,
td {
  border: none;
}

th {
  padding: 0;
  text-align: left;
  color: hsl(0, 0%, 0%);
  font-weight: 500;
}

sup {
  font-size: 0.5em;
  line-height: 1em;
  margin-top: -0.5em;
}

h1 {
  font-size: 1.3em;
  letter-spacing: 0.1rem;
  font-weight: 400;
  color: hsl(0, 0%, 0%);
}

h2 {
  font-size: 1.1em;
  line-height: 1.1;
  font-weight: 500;
  color: black;
}

h5 {
  font-size: 0.5em;
  font-weight: 600;
}

a,
.link {
  text-decoration: none;
  color: hsl(195, 70%, 40%);
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
}
a:hover,
.link:hover {
  color: hsl(195, 60%, 60%);
}

.error {
  color: hsl(350, 60%, 60%);
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

input,
button,
select,
textarea {
  width: 100%;
  padding: 1.2em 1.5em 1em 1.5em;
  border-radius: 0.5em;
  border: none;
  font-family: poppins, sans-serif;
  color: black;
}
input:focus,
button:focus,
select:focus,
textarea:focus {
  border: none;
  outline: none;
}

input {
  margin: 0;
  height: 3em;
  caret-color: black;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
input[type=checkbox] {
  width: 1.3em;
  height: 1.3em;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

textarea {
  resize: none;
  height: 10em;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
}

select {
  padding: 0 1.3em;
  color: hsl(0, 0%, 0%);
  margin: 0;
  height: 3em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

button {
  margin: 0;
  background: black;
  color: hsl(220, 27%, 96%);
  font-size: 1em;
  font-weight: 600;
  transition: 0.5s;
  box-shadow: inset rgba(17, 17, 26, 0.1) 0px 0px 10px, inset rgba(17, 17, 26, 0.1) 0px 0px 80px;
}
button:hover {
  background: linear-gradient(145deg, hsl(185, 50%, 60%) 0%, hsl(230, 50%, 60%) 100%);
  cursor: pointer;
  box-shadow: none;
}
button:active {
  background: linear-gradient(145deg, hsl(185, 50%, 55%) 0%, hsl(230, 50%, 60%) 100%);
  cursor: pointer;
  box-shadow: inset rgba(17, 17, 26, 0.5) 0px 0px 2px, inset rgba(17, 17, 26, 0.1) 0px 0px 4px, inset rgba(17, 17, 26, 0.05) 0px 0px 8px;
  transition: 0.1s;
  transform: scale(0.99);
}
button.redButton {
  background: linear-gradient(165deg, hsl(355, 55%, 55%) 0%, hsl(5, 55%, 40%) 100%);
}
button.redButton:hover {
  background: linear-gradient(145deg, hsl(355, 60%, 55%) 0%, hsl(5, 60%, 40%) 100%);
}
button.redButton:active {
  background: linear-gradient(165deg, hsl(355, 55%, 55%) 0%, hsl(5, 55%, 40%) 100%);
  box-shadow: inset rgba(17, 17, 26, 0.5) 0px 0px 2px, inset rgba(17, 17, 26, 0.1) 0px 0px 4px, inset rgba(17, 17, 26, 0.05) 0px 0px 8px;
  transform: scale(0.99);
}

.button-secondairy {
  background: linear-gradient(165deg, hsl(185, 70%, 93%) 0%, hsl(230, 70%, 93%) 100%);
  border: 3px solid hsl(220, 50%, 60%);
  color: hsl(220, 50%, 60%);
}
.button-secondairy:hover {
  background: linear-gradient(145deg, hsl(185, 70%, 90%) 0%, hsl(230, 70%, 90%) 100%);
  color: hsl(220, 50%, 60%);
  border: 3px solid hsl(220, 50%, 60%);
}
.button-secondairy:active {
  background: linear-gradient(145deg, hsl(185, 50%, 85%) 0%, hsl(230, 50%, 90%) 100%);
  color: hsl(220, 50%, 60%);
  border: 3px solid hsl(220, 50%, 60%);
}
.button-secondairy:focus {
  background: linear-gradient(145deg, hsl(185, 50%, 85%) 0%, hsl(230, 50%, 90%) 100%);
  color: hsl(220, 50%, 60%);
  border: 3px solid hsl(220, 50%, 60%);
}

form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.hide {
  opacity: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: hsl(0, 0%, 100%) hsl(0, 0%, 100%);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 100%);
  border-radius: 20px;
}

.home {
  font-size: 1.1em;
  background-color: white;
}

.hero {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  padding: 2.5em;
  font-size: 1.1em;
  gap: 2em;
}
.hero img {
  width: 3em;
  height: 6em;
  border-radius: 0;
}
.hero h1 {
  font-size: 1.9em;
  color: hsl(220, 70%, 60%);
  justify-content: center;
}
.hero p {
  color: hsl(220, 50%, 40%);
  margin: 0.3em 0;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  margin-bottom: 3em;
}
.button-container .button-secondairy {
  z-index: 1;
}
.button-container .watchDemo {
  z-index: 2;
}

.features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
}

@media screen and (max-width: 650px) {
  .feature {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
  }
}
@media screen and (min-width: 651px) {
  .feature {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
    align-items: flex-start;
  }
}
.feature .screenshot {
  border-radius: 0.7em;
}
@media screen and (max-width: 650px) {
  .feature .screenshot {
    width: 13em;
    height: 25em;
  }
}
@media screen and (min-width: 651px) {
  .feature .screenshot {
    width: 13em;
    height: 25em;
  }
}
.feature .feature-card {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  align-items: left;
}
@media screen and (max-width: 650px) {
  .feature .feature-card {
    padding: 2em;
    width: 100%;
  }
}
@media screen and (min-width: 651px) {
  .feature .feature-card {
    padding: 3em;
    flex-grow: 1;
    height: 100%;
  }
}
.feature .feature-card h2 {
  margin-bottom: 0.5em;
}
.feature .feature-card img {
  width: 2em;
  height: 2em;
  border-radius: 0;
}
.feature .feature-card .feature-card-element {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
  align-items: center;
}

.faq-section {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5em;
}
.faq-section .faq-item {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  padding: 1em 1.3em;
}
.faq-section .faq-item summary {
  cursor: pointer;
  font-weight: 500;
  opacity: 0.6;
}
.faq-section .faq-item .faq-answer p {
  margin: 0.3em 0;
}

.legal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
}
.legal summary {
  cursor: pointer;
  font-weight: 500;
  font-size: 1.2em;
}
.legal .legal-header h1 {
  margin: 0;
}
.legal .legal-header p {
  margin: 0.5em 0;
}
.legal article {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}
.legal article h2 {
  margin-bottom: 0;
}
.legal article p {
  margin: 0.3em 0;
}

.errorPage {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3em;
}
.errorPage img {
  width: auto;
  height: 8.5em;
}
.errorPage .errorText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
  width: 100%;
}
.errorPage .errorText p {
  margin: 0.1em 0;
}

header {
  position: sticky;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 3.5em;
  height: 3.5em;
  max-height: 3.5em;
  z-index: 10;
  position: relative;
}
header #logo {
  cursor: pointer;
  position: absolute;
  width: 1em;
  height: 2em;
  left: 2em;
  top: 0.75em;
  transition: 0.5s;
}
header #logo:hover {
  transition: 0.5s;
}
header #logo:active {
  transform: scale(0.95);
  transition: 0.1s;
}
header .navigation {
  position: absolute;
  top: 0;
  left: 5em;
  width: calc(100% - 4em);
  display: flex;
  justify-content: flex-start;
  gap: 2em;
  height: 100%;
  align-items: center;
  text-align: center;
}
header .loginSignUp {
  position: absolute;
  right: 2em;
  display: flex;
  justify-content: flex-end;
  gap: 0em;
  height: 100%;
  align-items: center;
  text-align: center;
  gap: 0.5em;
  color: hsl(220, 50%, 40%);
}
@media screen and (max-width: 460px) {
  header .loginSignUp {
    top: 2em;
  }
}
@media screen and (min-width: 461px) {
  header .loginSignUp {
    top: 0;
  }
}
header .loginSignUp .login {
  padding: 0;
  color: hsl(200, 60%, 60%);
}
header .loginSignUp .signup {
  padding: 0;
  color: hsl(200, 50%, 40%);
}
header a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: hsl(0, 0%, 0%);
  transition: 0.5s;
  height: 100%;
}
header a:hover {
  color: hsl(0, 0%, 0%);
  opacity: 0.5;
  transition: 0.1s;
}
header .hamburger-menu {
  position: absolute;
  right: 2em;
  top: 1em;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 20px;
  width: 25px;
  cursor: pointer;
  z-index: 10;
  transition: 0.5s;
}
header .hamburger-menu .line {
  width: 100%;
  height: 4px;
  background: hsl(0, 0%, 100%);
  border-radius: 4px;
}
header .hamburger-menu:hover {
  opacity: 0.6;
  transition: 0.1s;
}
header .menu-mobile {
  max-width: 950px;
  width: 100vw;
  flex-direction: row;
  position: absolute;
  background: linear-gradient(45deg, hsl(240, 40%, 90%) 0%, hsl(200, 40%, 90%) 100%);
  border-radius: 1em;
  top: 3.5em;
  margin: 0;
  padding: 2em;
  height: calc(100vh - 3.5em);
}
header .menu-mobile .navigation-mobile {
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  border-radius: 1em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
header .menu-mobile .navigation-mobile a {
  display: inline;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  border-radius: 0.7em;
  padding: 1.5em 2em;
  text-align: center;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: calc(950px - 4em);
  width: calc(100vw - 4em);
  margin: 0 2em;
  padding: 1.5em 2em;
  background: white;
  border-radius: 0.7em 0.7em 0 0;
  z-index: 10;
}
@media screen and (min-width: 651px) {
  footer {
    align-items: center;
  }
}
footer nav {
  display: flex;
  color: hsl(0, 0%, 100%);
}
@media screen and (max-width: 650px) {
  footer nav {
    flex-direction: column;
    gap: 20px;
  }
}
@media screen and (min-width: 651px) {
  footer nav {
    flex-direction: row;
    gap: 2em;
  }
}
footer a {
  font-weight: 400;
  opacity: 0.7;
}
footer a:hover {
  opacity: 1;
}
footer a:active {
  opacity: 1;
  transform: scale(0.95);
}
footer .rights {
  text-align: right;
  margin: 0;
}

.card {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;
  color: black;
}
.card img {
  width: 5em;
  height: 5em;
  color: black;
}
.card .card-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
  color: black;
}

