*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	border: none;
	outline: none;
}
*:active {
	outline: none;
}
* {
	-webkit-tap-highlight-color: transparent;
	// -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	// user-select: none;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: poppins, sans-serif;
	user-select: none;
	background: white;
}

.App {
	@include flexRowSpaceBetween();
}

.center {
	@include maxWidth();
	font-weight: 400;
	color: $color-primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	// Change the font-size based on breakpoints
	@media screen and (max-width: 300px) {
		font-size: 0.7em;
	}
	@media screen and (min-width: 301px) and (max-width: 499px) {
		font-size: 0.8em;
	}
	@media screen and (min-width: 500px) {
		font-size: 0.9em;
	}
}
.left,
.right {
	// background: $gradient-sides;
	height: 100vh;
	width: calc((100vw - $maxWidth) / 2);
	grid-row: 1 / 3; /* Spans from the first to the second row */
	z-index: 12;
	z-index: 12;
}
main {
	flex-grow: 1;
	padding: 2em;
	z-index: 0;
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	gap: 2em;

	.combinedTitle {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 0.2em;
	}
	h4 {
		font-size: 0.7em;
		padding-bottom: 0.2em;
		font-weight: normal;
	}
}
h1,
h2,
h3,
h4,
h5,
p,
table {
	margin: 0;
	padding: 0;
}
p {
	margin: 1em 0;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}
table,
th,
td {
	border: none;
}
th {
	padding: 0;
	text-align: left;
	color: $color-primary;
	font-weight: 500;
}

sup {
	font-size: 0.5em;
	line-height: 1em;
	margin-top: -0.5em;
}

h1 {
	font-size: 1.3em;
	letter-spacing: 0.1rem;
	font-weight: 400;
	color: $color-primary;
}
h2 {
	font-size: 1.1em;
	line-height: 1.1;
	font-weight: 500;
	color: black;
}

h5 {
	font-size: 0.5em;
	font-weight: 600;
}

a,
.link {
	text-decoration: none;
	color: $color-link;
	font-weight: 500;
	transition: $transition-medium;
	cursor: pointer;

	&:hover {
		color: $color-hover;
	}
}

.error {
	color: hsl($hue-incorrect - 10, $saturation-medium, $brightness-medium);
}

details > summary {
	list-style: none;
}
details > summary::-webkit-details-marker {
	display: none;
}

.flexRow {
	@include flexRowSpaceBetween();
	align-items: center;
	width: 100%;
}

//////////////////////
//  INPUT & BUTTON  //
//////////////////////
input,
button,
select,
textarea {
	width: 100%;
	padding: 1.2em 1.5em 1em 1.5em;
	border-radius: 0.5em;
	border: none;
	font-family: poppins, sans-serif;
	color: black;

	&:focus {
		border: none;
		outline: none;
	}
}
input {
	margin: 0;
	height: 3em;
	caret-color: black;
	box-shadow: $standard-inset-box-shadow;

	&[type="checkbox"] {
		width: 1.3em;
		height: 1.3em;
		box-shadow: $standard-inset-box-shadow;
	}
}
textarea {
	resize: none;
	height: 10em;
	box-shadow: $standard-inset-box-shadow;
	margin: 0;
}
select {
	padding: 0 1.3em;
	color: $color-primary;
	margin: 0;
	height: 3em;
	box-shadow: $standard-box-shadow;
}

button {
	margin: 0;
	background: black;
	color: $color-off_white;
	font-size: 1em;
	font-weight: 600;
	transition: $transition-medium;
	box-shadow:
		inset rgba(17, 17, 26, 0.1) 0px 0px 10px,
		inset rgba(17, 17, 26, 0.1) 0px 0px 80px;

	&:hover {
		background: $gradient-button_hover;
		cursor: pointer;
		box-shadow: none;
	}

	&:active {
		background: $gradient-button_active;
		cursor: pointer;
		box-shadow: $complex-inset-box-shadow;
		transition: $transition-fast;
		transform: scale(0.99);
	}

	&.redButton {
		background: $gradient-incorrect;

		&:hover {
			background: $gradient-incorrect_reverse;
		}

		&:active {
			background: $gradient-incorrect;
			box-shadow: $complex-inset-box-shadow;
			transform: scale(0.99);
		}
	}
}
.button-secondairy {
	background: $gradient-button-secondairy;
	border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);

	&:hover {
		background: $gradient-button_hover-secondairy;
		color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);
		border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	}
	&:active {
		background: $gradient-button_active-secondairy;
		color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);
		border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	}
	&:focus {
		background: $gradient-button_active-secondairy;
		color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);
		border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	}
}
form {
	display: flex;
	flex-direction: column;
	gap: 2em;
}
.hide {
	// display: none;
	opacity: 0;
}
///////////////
// SCROLLBAR //
///////////////
// Works on Firefox
* {
	scrollbar-width: thin;
	scrollbar-color: $color-secondairy $color-secondairy;
}

*::-webkit-scrollbar {
	width: 8px;
	height: 12px;
}

*::-webkit-scrollbar-thumb {
	background-color: $color-secondairy;
	border-radius: 20px;
}
