// IMPORTANT VALUES
$maxWidth: 950px;
$headerHeight: 3.5em;
$maxWidthMobile: 650px;
$minWidthWeb: 651px;

// ANIMATIONS
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideIn {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

.animate-fade-in {
	animation: fadeIn 1s;
}

.animate-slide-in {
	animation: slideIn 1s ease-out forwards;
}

// COLORS
$hue-primary: 220;
$hue-link: 195;
$hue-correct: 170;
$hue-incorrect: 0;

$saturation-low: 40%;
$saturation-medium: 60%;
$saturation-high: 70%;

$brightness-low: 40%;
$brightness-medium: 60%;
$brightness-high: 80%;

$color-primary: hsl(0, 0%, 0%);

$color-secondairy: hsl(0, 0%, 100%);

$color-link: hsl($hue-link, $saturation-high, $brightness-low);
$color-hover: hsl($hue-link, $saturation-medium, $brightness-medium);

$color-almost_black: hsl($hue-primary, 39%, 6%);

$color-blue: hsl(0, 0%, 100%);
$color-blue_gradient: hsl(0, 0%, 100%);

$color-white: hsl(0, 0%, 100%);
$color-off_white: hsl($hue-primary, 27%, 96%);

// COLORS OLD
$color-blue_gradient: #9dbfd6;

// OPACITY
$opacity-bg: 0.15;
$opacity-card: 0.5;
$opacity-card_hover: 0.8;
$opacity-card_active: 0.8;

// ANIMATION
$transition-fast: 0.1s;
$transition-medium: 0.5s;
$transition-slow: 1s;
$transition-ultra_slow: 5s;

// BOX SHADOW
$standard-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
$standard-inset-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
$deep-box-shadow:
	inset rgba(5, 71, 68, 0.1) 0px 0px 4px,
	inset rgba(5, 71, 68, 0.05) 0px 0px 8px;
$complex-inset-box-shadow:
	inset rgba(17, 17, 26, 0.5) 0px 0px 2px,
	inset rgba(17, 17, 26, 0.1) 0px 0px 4px,
	inset rgba(17, 17, 26, 0.05) 0px 0px 8px;

// GRADIENTS
$gradient-completed: linear-gradient(
	175deg,
	hsl($hue-correct + 5, $saturation-low, $brightness-medium - 5) 0%,
	hsl($hue-correct - 5, $saturation-low, $brightness-medium) 100%
);

$gradient-correct: linear-gradient(
	155deg,
	hsl($hue-correct - 10, $saturation-medium - 5, $brightness-low) 0%,
	hsl($hue-correct + 10, $saturation-medium - 5, $brightness-low - 5) 100%
);
$gradient-incorrect: linear-gradient(
	165deg,
	hsl($hue-incorrect - 5, $saturation-medium - 5, $brightness-medium - 5) 0%,
	hsl($hue-incorrect + 5, $saturation-medium - 5, $brightness-low) 100%
);
$gradient-incorrect_reverse: linear-gradient(
	145deg,
	hsl($hue-incorrect - 5, $saturation-medium, $brightness-medium - 5) 0%,
	hsl($hue-incorrect + 5, $saturation-medium, $brightness-low) 100%
);

$gradient-button: linear-gradient(
	165deg,
	hsl($hue-link - 10, $saturation-medium - 10, $brightness-medium) 0%,
	hsl($hue-primary + 10, $saturation-medium - 10, $brightness-medium) 100%
);
$gradient-button_hover: linear-gradient(
	145deg,
	hsl($hue-link - 10, $saturation-medium - 10, $brightness-medium) 0%,
	hsl($hue-primary + 10, $saturation-medium - 10, $brightness-medium) 100%
);
$gradient-button_active: linear-gradient(
	145deg,
	hsl($hue-link - 10, $saturation-medium - 10, $brightness-medium - 5) 0%,
	hsl($hue-primary + 10, $saturation-medium - 10, $brightness-medium - 0) 100%
);

$gradient-button-secondairy: linear-gradient(
	165deg,
	hsl($hue-link - 10, $saturation-medium + 10, $brightness-high + 13) 0%,
	hsl($hue-primary + 10, $saturation-medium + 10, $brightness-high + 13) 100%
);
$gradient-button_hover-secondairy: linear-gradient(
	145deg,
	hsl($hue-link - 10, $saturation-medium + 10, $brightness-high + 10) 0%,
	hsl($hue-primary + 10, $saturation-medium + 10, $brightness-high + 10) 100%
);
$gradient-button_active-secondairy: linear-gradient(
	145deg,
	hsl($hue-link - 10, $saturation-medium - 10, $brightness-high + 10 - 5) 0%,
	hsl($hue-primary + 10, $saturation-medium - 10, $brightness-high + 10 - 0) 100%
);

$gradient-interval_button: linear-gradient(
	165deg,
	hsl($hue-link - 10, $saturation-medium, $brightness-low) 0%,
	hsl($hue-primary, $saturation-medium - 10, $brightness-low) 100%
);
$gradient-interval_button_hover: linear-gradient(
	145deg,
	hsl($hue-link - 10, $saturation-medium, $brightness-low) 0%,
	hsl($hue-primary, $saturation-medium - 10, $brightness-low) 100%
);
$gradient-interval_button_active: linear-gradient(
	145deg,
	hsl($hue-link - 10, $saturation-medium, $brightness-low - 5) 0%,
	hsl($hue-primary, $saturation-medium - 10, $brightness-low - 0) 100%
);

$gradient-transparant_white: linear-gradient(
	135deg,
	rgba($color-off_white, $opacity-card) 0%,
	rgba($color-white, $opacity-card) 100%
);
$gradient-semi_transparant_white: linear-gradient(
	135deg,
	rgba($color-off_white, $opacity-card_hover) 0%,
	rgba($color-white, $opacity-card_hover) 100%
);
$gradient-white: linear-gradient(
	135deg,
	rgba($color-white, $opacity-card_active) 0%,
	rgba($color-white, $opacity-card_active) 100%
);

$gradient-disabled: linear-gradient(
	165deg,
	hsl($hue-primary, $saturation-low - 15, $brightness-high) 0%,
	hsl($hue-primary, $saturation-low - 5, $brightness-high - 5) 100%
);

$gradient-disabled_interval_button: linear-gradient(
	165deg,
	hsl($hue-primary, $saturation-low, $brightness-high + 5) 0%,
	hsl($hue-primary, $saturation-low, $brightness-high + 5) 100%
);
$gradient-bg: linear-gradient(
	45deg,
	hsl($hue-primary + 20, $saturation-low, 90%) 0%,
	hsl($hue-primary - 20, $saturation-low, 90%) 100%
);

$gradient-blue: linear-gradient(5deg, $color-blue_gradient 0%, $color-blue 100%);

////////////
// MIXINS //
////////////
@mixin maxWidth() {
	max-width: $maxWidth;
	width: 100vw;
}

// FLEXBOX
@mixin flexRowSpaceBetween() {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@mixin flexWrapSpaceAround() {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-content: space-around;
}

@mixin flexCenter() {
	display: flex;
	justify-content: center;
	align-items: center;
}

// MODALS
@mixin modalBase() {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vh);
	background-color: rgba(207, 210, 228, 0.7);
}

@mixin modalContent() {
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	margin: auto;
	padding: 2em;
	border-radius: 1em;
	max-height: calc(100% - 4em);
	width: calc(100% - 4em);
	max-width: calc($maxWidth - 4em);
	background: $gradient-bg;
	box-shadow:
		0 0 64px rgba(0, 0, 0, 0.15),
		0 0 8px rgba(0, 0, 0, 0.05);
}

// BUTTONS
@mixin buttonStyle($radius) {
	border-radius: $radius;
	box-shadow: $standard-box-shadow;
	justify-content: center;
	display: flex;
	color: white;
	align-items: center;
	text-align: center;
	cursor: pointer;
	transition: $transition-medium;
	background: $gradient-interval_button;

	&:hover {
		background: $gradient-interval_button_hover;
		transition: $transition-medium;
		opacity: 0.8;
	}

	&:active {
		background: $gradient-interval_button_active;
		transition: $transition-medium;
		opacity: 0.8;
		box-shadow: inset 4px 12px 60px -29px rgba(0, 0, 0, 0.5);
		transform: scale(0.98);
	}
}
// CARD BASICS
@mixin basicCard {
	border-radius: 0.7em;
	padding: 2em;
	text-align: left;
	transition: $transition-medium;
	background: $gradient-transparant_white;
	box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
}

@mixin basicCardLink() {
	cursor: pointer;
	&:hover {
		background: $gradient-semi_transparant_white;
		transition: $transition-medium;
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0);
	}
	&:active {
		background: $gradient-white;
		transition: $transition-fast;
		box-shadow: $standard-inset-box-shadow;
	}
}
