////////////////////
//      HOME      //
////////////////////
.home {
	font-size: 1.1em;
	background-color: white
}

.hero {
	@include basicCard();
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1em;
	padding: 2.5em;
	font-size: 1.1em;
	gap: 2em;

	img {
		width: 3em;
		height: 6em;
		border-radius: 0;
	}

	h1 {
		font-size: 1.9em;
		color: hsl($hue-primary, $saturation-medium + 10, $brightness-medium);
		justify-content: center;
	}

	p {
		color: hsl($hue-primary, $saturation-low + 10, $brightness-low);
		margin: 0.3em 0;
		justify-content: center;
	}
}

.button-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2em;
	margin-bottom: 3em;

	.button-secondairy {
		z-index: 1;
	}

	.watchDemo {
		z-index: 2;
	}
}

.features {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2em;
}

.feature {
	@media screen and (max-width: $maxWidthMobile) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 2em;
	}
	@media screen and (min-width: $minWidthWeb) {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 2em;
		align-items: flex-start;
	}

	.screenshot {
		@media screen and (max-width: $maxWidthMobile) {
			display: none;
			
		}
		@media screen and (min-width: $minWidthWeb) {
			width: 13em;
			height: 25em;
		}
		border-radius: 0.7em;
	}

	.feature-card {
		@include basicCard();
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 1em;
		align-items: left;

		@media screen and (max-width: $maxWidthMobile) {
			padding: 2em;
			width: 100%;
		}
		@media screen and (min-width: $minWidthWeb) {
			padding: 3em;
			flex-grow: 1;
			height: 100%;
		}

		h2 {
			margin-bottom: 0.5em;
		}
		img {
			width: 2em;
			height: 2em;
			border-radius: 0;
		}

		.feature-card-element {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			gap: 1em;
			align-items: center;
		}
	}
}

////////////////////
/// FAQ SECTION ////
////////////////////
.faq-section {
	@include basicCard();
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1.5em;

	.faq-item {
		@include basicCard();
		background: $gradient-semi_transparant_white;
		padding: 1em 1.3em;
		summary {
			cursor: pointer;
			// font-style: italic;
			font-weight: 500;
			opacity: 0.6;
			// padding: 0 1em;
		}

		.faq-answer {
			p {
				margin: 0.3em 0;
			}
		}
	}
}
//////////////
/// LEGAL ////
//////////////
.legal {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1em;

	summary {
		cursor: pointer;
		font-weight: 500;
		font-size: 1.2em;
	}

	.legal-header {
		h1 {
			margin: 0;
		}
		p {
			margin: 0.5em 0;
		}
	}

	article {
		@include basicCard();
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: left;

		h2 {
			margin-bottom: 0;
		}
		p {
			margin: 0.3em 0;
		}
	}
}
////////////////
// ERROR PAGE //
////////////////
.errorPage {
	@include basicCard();
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 3em;

	img {
		width: auto;
		height: 8.5em;
	}

	.errorText {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2em;
		width: 100%;

		p {
			margin: 0.1em 0;
		}
	}
}
