////////////
// HEADER //
////////////
header {
	position: sticky;
	padding-bottom: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	min-height: $headerHeight;
	height: $headerHeight;
	max-height: $headerHeight;

	z-index: 10;
	position: relative;

	#logo {
		cursor: pointer;
		position: absolute;
		width: 1em;
		height: 2em;
		left: 2em;
		top: calc($headerHeight / 2 - 1em);
		transition: $transition-medium;
		&:hover {
			transition: $transition-medium;
		}
		&:active {
			transform: scale(0.95);
			transition: $transition-fast;
		}
	}

	.navigation {
		position: absolute;
		top: 0;
		left: 5em;
		width: calc(100% - 4em);
		display: flex;
		justify-content: flex-start;
		gap: 2em;
		height: 100%;
		align-items: center;
		text-align: center;
	}

	.loginSignUp {
		position: absolute;
		@media screen and (max-width: 460px) {
			top: 2em;
		}
		@media screen and (min-width: 461px) {
			top: 0;
		}
		right: 2em;
		display: flex;
		justify-content: flex-end;
		gap: 0em;
		height: 100%;
		align-items: center;
		text-align: center;
		gap: 0.5em;
		color: hsl($hue-primary, $saturation-low + 10, $brightness-low);

		.login {
			padding: 0;
			color: hsl($hue-primary - 20, $saturation-medium, $brightness-medium);
		}
		.signup {
			padding: 0;
			color: hsl($hue-primary - 20, $saturation-low + 10, $brightness-low);
		}
	}

	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: $color-primary;
		transition: $transition-medium;
		height: 100%;

		&:hover {
			color: $color-primary;
			opacity: 0.5;
			transition: $transition-fast;
		}
	}

	.hamburger-menu {
		position: absolute;
		right: 2em;
		top: 1em;
		display: flex;
		flex-direction: column;
		gap: 4px;
		height: 20px;
		width: 25px;
		cursor: pointer;
		z-index: 10;
		transition: $transition-medium;

		.line {
			width: 100%;
			height: 4px;
			background: $color-secondairy;
			border-radius: 4px;
		}
		&:hover {
			opacity: 0.6;
			transition: $transition-fast;
		}
	}
	.menu-mobile {
		@include maxWidth();
		flex-direction: row;
		position: absolute;
		background: $gradient-bg;
		border-radius: 1em;
		top: $headerHeight;
		margin: 0;
		padding: 2em;
		height: calc(100vh - $headerHeight);

		.navigation-mobile {
			background: $gradient-transparant_white;
			border-radius: 1em;
			padding: 2em;
			display: flex;
			flex-direction: column;
			gap: 1.5em;

			a {
				display: inline;
				background: $gradient-transparant_white;
				border-radius: 0.7em;
				padding: 1.5em 2em;
				text-align: center;
			}
		}
	}
}

///////////////////////
// NAVIGATION FOOTER //
///////////////////////
footer {
	@include flexRowSpaceBetween();
	max-width: calc($maxWidth - 4em);
	width: calc(100vw - 4em);
	margin: 0 2em;
	padding: 1.5em 2em;
	background: white;
	border-radius: 0.7em 0.7em 0 0;
	z-index: 10;
	@media screen and (min-width: $minWidthWeb) {
		align-items: center;
	}

	nav {
		display: flex;
		@media screen and (max-width: $maxWidthMobile) {
			flex-direction: column;
			gap: 20px;
		}
		@media screen and (min-width: $minWidthWeb) {
			flex-direction: row;
			gap: 2em;
		}
		color: $color-secondairy;
	}

	a {
		font-weight: 400;
		opacity: 0.7;

		&:hover {
			opacity: 1;
		}
		&:active {
			opacity: 1;
			transform: scale(0.95);
		}
	}

	.rights {
		text-align: right;
		margin: 0;
	}
}

//////////////////
// SELECT CARDS //
//////////////////
.card {
	@include basicCard();
	// @include basicCardLink();
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.5em;
	color: black;

	img {
		width: 5em;
		height: 5em;
		color: black;
	}

	.card-text {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 0.5em;
		color: black;
	}
}
